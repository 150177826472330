import React,{useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import {Tools} from './Tools';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReportLastTag} from './Inc';

interface Props {
    close():void,
    update(name:string,tag:ReportLastTag):void,
    remove(name:string):void,
    name:string,
    tag:ReportLastTag,
}

const TagInput = (props:Props)=> {
    const [states,setStates] = useState({
        start: 0,
    })
    
    const change = (name:"start",value:string)=>{
        let n = parseInt(Tools.toHan(value));
        //console.log(value,n);
        if( isNaN(n) ){
            n = 0;
        }
        setStates({...states,start:n});
    }

    const update = ()=>{
        props.update(props.name,{...props.tag,start:states.start});
    }

    const remove = ()=>{
        props.remove(props.name);
    }

    const convDate = (date?:string)=>{
        if( !date ){
            return "なし";
        }
        return date.substr(0,4)+"/"+date.substr(4,2)+"/"+date.substr(6,2);
    }

    useEffect( ()=>{
        if( props.tag ){
            setStates({...states,start:props.tag.start});
        }
    },[props.tag]);

    if(!props.tag){
        return (null);
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.name!==""}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="tagPicker">
                <ModalHeader close={props.close}/>
                <div className="dialogRoot">
                    <h2><FontAwesomeIcon icon="tag"/> {props.name}</h2>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <FormControl fullWidth={true}>
                                <FormLabel component="label" className="formLabel">回数</FormLabel>
                                <span>{props.tag.count}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl fullWidth={true}>
                                <FormLabel component="label" className="formLabel">最終回</FormLabel>
                                <span>{convDate(props.tag.date)}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ColInput id="start" name="カウンタの開始値" type="text" value={String(states.start)} change={change}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Button variant="outlined" color="secondary" onClick={remove} style={{width:"100%"}} disabled={props.tag.count>0}>削除</Button>
                        </Grid>
                        <Grid item xs={9}>
                            <Button variant="contained" color="primary" onClick={update} style={{width:"100%"}}>開始値を変更</Button>
                        </Grid>
                    </Grid>
                    {props.tag.count>0 &&
                    <div className="hint">※回数が0でないと削除できません</div>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default TagInput;
