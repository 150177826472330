import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {Tools} from './Tools';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
import Toast from './Toast';

interface Props {
    close():void,
    name:string,
    email:string,
    update(email:string):void,
    isOpen:boolean,
}

const ProfileEmail = (props:Props)=> {
    const [states,setStates] = useState({
        email: "",
    })
    const [toast,setToast] = useState("");
    
    const change = (name:"email",value:string)=>{
        setStates({...states,email:value});
    }

    const update = ()=>{
        const value = states.email.trim();
        if(value===""){
            setToast("新しいメールアドレスを教えてください");
            return;
        }
        props.update(value);
    }

    const closeToast = ()=>{
        setToast("");
    }

    useEffect(()=>{
        setStates({...states,email:""});
    },[]);


    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="tagPicker">
                <ModalHeader close={props.close}/>
                <div className="dialogRoot">
                    <h2>{props.name}</h2>
                    <FormControl fullWidth={true}>
                        <FormLabel component="label" className="formLabel">現在のメールアドレス</FormLabel>
                        <div className="colData">
                            <div className="value">{props.email}</div>
                        </div>
                    </FormControl>

                    <ColInput id="name" name="新しいメールアドレス" placeholder={props.email} type="text" value={states.email} change={change}/>
                    <Button variant="contained" color="primary" onClick={update} style={{width:"100%"}}>変更する</Button>
                </div>
                <Toast close={closeToast} mes={toast}/>
            </div>
        </Modal>
    );
}

export default ProfileEmail;
