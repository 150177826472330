import React from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
//import Hidden from '@material-ui/core/Hidden';
import logo from "./imgs/namelogo.png";

const Logo = ()=> {
    return (
        <Link to="/" style={{textDecoration:"none",color:"#000000",marginTop:"3px"}}><img src={logo} alt="logo" style={{width:"120px",height:"32px"}}/></Link>
    )
}

export default Logo;