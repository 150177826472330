import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import MultiLines from './MultiLines';
import {Tools} from './Tools';
//import useStyles from './Styles';

interface Props {
    mes: string,
    yes():void,
    no():void,
    isOpen:boolean,
}

const Yesno = (props:Props)=> {
    //const classes = useStyles();

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.no}
        >
            <div style={Tools.getModalStyle()} className="alert">
                <div className="dialogRoot">
                    <div style={{marginBottom:"1em"}}>
                        <MultiLines text={props.mes}/>
                    </div>
                    <Button variant="contained" color="default" style={{width:"40%"}} onClick={props.no}>いいえ</Button>
                    <Button variant="contained" color="primary" style={{width:"60%"}} onClick={props.yes}>はい</Button>
                </div>
            </div>
        </Modal>
    );
}

export default Yesno;
