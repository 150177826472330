import React,{useState} from 'react';
import {Redirect} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Title from './Title';
import Loading from './Loading';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import "./Login.scss";

const Login = ()=> {
    const [states,setStates] = useState({
        email:"",
        isSend:false,
        isLoading:false,
    })
    const [isFinished,setIsFinished] = useState(false);
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
            setIsFinished(true);
        });
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
        },()=>{
            setToast("メールの送信に失敗しました");
        });      
    }
    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }

    const closeToast = ()=>{
        setToast("");
    }
    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }

    if( isFinished ){
		return (
			<Redirect to="/"/>
		)
    }

    if( !userState.ready ){
        return (null);
    }

    return (
        <div id="login" className="miniRoot">
            <Paper className="paper">
                <Title size={3} text={"ログイン"} icon={"user-circle"}></Title>
                {!states.isSend ? (
                <React.Fragment>
                    <div className="talk">
                        このサービスをご利用いただくには、グーグルのアカウントまたはメールアドレスが必要です。
                    </div>
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" onClick={signInWithGoogle}>
                            <FontAwesomeIcon icon={['fab','google']} style={{marginRight:"0.5em"}}/>Googleでログイン
                        </Button>
                    </FormControl>

                    <div className="mailLoginInput">
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
                                placeholder="メールアドレス"
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true}>
                            <Button variant="contained" color="primary" onClick={validateEmail}>
                                <FontAwesomeIcon icon="envelope" style={{marginRight:"0.5em"}}/>メールでログイン
                            </Button>
                        </FormControl>
                    </div>
                </React.Fragment>
                ):(
                    <div className="talk">
                        <p>{states.email}にメールを送信いたしましたのでご確認ください。</p>
                        <p>メールに書かれたURLは1回限り有効です。</p>
                    </div>
                )}
            </Paper>
            <Toast mes={toast} close={closeToast}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default Login;
