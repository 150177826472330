import React from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import Drawer from '@material-ui/core/Drawer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from './Logo';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';
import "./TopBar.scss";

interface Props {
    //redirect(to:string):void,
}

const TopBar = (props:Props)=> {
    const userState = UserContainer.useContainer();

    return (
        <AppBar position="fixed" className="appbar" style={{zIndex:Theme.zIndex.drawer+1}}>
            <Toolbar variant="dense" className="toolbar">
                <Logo/>
                {userState.user &&
                <div className="menuPerson">
                    <Link to="/tags" className="link"><IconButton color="default" className="personButton" aria-label="logined" style={{marginRight:"0.25em"}}>
                        <FontAwesomeIcon icon="tags"/>
                    </IconButton></Link>
                    <Link to="/profile" className="link"><IconButton color="default" className="personButton" aria-label="logined" style={{marginRight:"0.25em"}}>
                        <AccountBoxIcon style={{fontSize:"160%"}}/>
                    </IconButton></Link>
                    <Link to="/report/new" className="link"><Button variant="contained" color="primary"><FontAwesomeIcon icon="edit" style={{marginRight:"0.5em"}}/>書く</Button></Link>
                </div>
                }
                {userState.ready && !userState.user &&
                <div className="menuPerson">
                    <Link to="/login" className="link"><Button variant="outlined" style={{backgroundColor:"#FFFFFF"}}>ログイン</Button></Link>
                </div>
                }
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;