import React,{useEffect,useState} from 'react';
import {RouteComponentProps, Redirect} from 'react-router-dom'//yarn add @types/react-router-dom
import useReactRouter from 'use-react-router';
import Main from "./Main";
import Secret from './Secret';
import store from "./store";
import {Inc,Profile} from "./Inc";



interface RCProps extends RouteComponentProps<{gid?:string}> {
	
}

interface Props {
    
}

const Enter = (props:Props)=> {
    const [states,setStates] = useState({
        profile:Inc.defaultProfile(),
        userId:"",
    });
    const rcp:RCProps = useReactRouter();
    useEffect( ()=>{
        if( rcp.match.params.gid ){
            let gid:string = rcp.match.params.gid as string;
            store.getPublicProfile(gid,(userId:string,profile:Profile)=>{
                setStates({profile:profile,userId:userId});
            },()=>{
                setStates({...states,userId:"none"});
            })
        } else {
            setStates({...states,userId:"none"});
        }
    },[rcp.match.params.gid])

    if( states.userId==="" ){
        return (null);
    }

    if( states.userId==="none" ){
        return (
            <Redirect to="/"/>
        );
    }

    if( states.profile.secret==="0" ){
        return (
            <Secret/>
        );    
    }

    //console.log("enter",states.userId,states.profile);

    return (
        <Main userId={states.userId} profile={states.profile}/>
    );
}

export default Enter;
