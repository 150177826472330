import React,{useEffect,useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColInput from './ColInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Yesno from './Yesno';
import MultiLines from './MultiLines';
import TagPick from './TagPick';
import Loading from './Loading';
import store from "./store";
import {UserContainer} from './UserContainer';
import {Report,ReportLastTags,ReportUpdate,Profile,ReportSearch} from './Inc';
//import "./Main.scss";

interface Props {
    
}

const ReportList = (props:Props)=> {
    const defReports:Report[] = [];
    const defTags:ReportLastTags = {};
    const defSearch:ReportSearch = {
        date: "",
        tag: "",
    };
    const [states,setStates] = useState({
        reports:defReports,
        removeIndex:-1,
        endDateKey: "",
        isEnd:false,
        isLoading:true,
        isTagPick:false,
        searchTag: "",
        searchDate: "",
        tags:defTags,
        redirectTo: "",
    })
    const userState = UserContainer.useContainer();
    const checkProfile = (profile?:Profile)=>{
        if(!profile || profile.name==="" ){
            setStates({...states,redirectTo:"/profile/input"})
        } else {
            updateList();
        }
    }

    const updateList = (searchTag?:string)=>{
        if( !userState.user ){
            return;
        }
        if(!searchTag){
            searchTag = "";
        }
        store.getReports( userState.user.uid, states.endDateKey , defSearch , (reports:Report[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( reports.length>0 ){
                endDateKey = reports[reports.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,reports:reports,endDateKey:endDateKey,isEnd:isEnd,isLoading:false,removeIndex:-1});
        });
    }

    const loadMore = ()=>{
        if( !userState.user ){
            return;
        }
        setStates({...states,isLoading:true});
        store.getReports( userState.user.uid, states.endDateKey , defSearch , (reports:Report[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( reports.length>0 ){
                endDateKey = reports[reports.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,reports:states.reports.concat(reports),endDateKey:endDateKey,isEnd:isEnd,isLoading:false,removeIndex:-1});
        });
    }

    const changeSearchDate = (name:string,value:string)=>{
        setStates({...states,searchDate:value});
    }
    const changeSearchTag = (name:string,value:string)=>{
        setStates({...states,searchTag:value});
    }
    const search = ()=>{
        updateList(states.searchTag);
    }
    const openTagPick = ()=>{
        setStates({...states,isTagPick:true});
    }
    const closeTagPick = ()=>{
        setStates({...states,isTagPick:false});
    }
    const setSearchTag = (name:string)=>{
        setStates({...states,searchTag:name,isTagPick:false});
    }
    const clearSearchTag = ()=>{
        setStates({...states,searchTag:""});
    }

    const removeReport = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
        setStates({...states,removeIndex:parseInt(e.currentTarget.dataset.index)});
    }

    const yes = ()=>{
        if(userState.user){
            const report = states.reports[ states.removeIndex ];

            store.getProfile(userState.user.uid,(profile:Profile)=>{
                remove(report,profile);
            });
        }
    }
    const no = ()=>{
        setStates({...states,removeIndex:-1});
    }
    const remove = (report:Report,profile:Profile)=>{
        setStates({...states,isLoading:true});

        let lastTags:ReportLastTags = profile.tags;
        let fromDateKey = "";
        let toDateKey = "";
        let updates:ReportUpdate[] = [];
        const dateKey = report.doc.dateKey;
        for( var key in report.doc.tags ){
            const n = lastTags[key].dateKeys.indexOf(dateKey);
            const targetKeys = lastTags[key].dateKeys.slice(n+1);

            if( fromDateKey==="" || fromDateKey>targetKeys[0] ){
                fromDateKey = targetKeys[0];
            }
            if( toDateKey==="" || toDateKey<targetKeys[targetKeys.length-1] ){
                toDateKey = targetKeys[targetKeys.length-1];
            }

            if( lastTags[key].dateKeys[n-1] ){
                updates.push({
                    name: key,
                    count: lastTags[key].count - targetKeys.length - 1,
                    dateKey: lastTags[key].dateKeys[n-1],
                    dateKeys: targetKeys,
                });
            } else {
                updates.push({
                    name: key,
                    count: 0,
                    dateKey: "",
                    dateKeys: targetKeys,
                });
            }

            lastTags[key].count--;
            if( n===0 ){
                if(lastTags[key].dateKeys.length>1){
                    lastTags[key].dateKeys = lastTags[key].dateKeys.slice(1);
                } else {
                    lastTags[key].dateKeys = [];
                }
            } else {
                lastTags[key].dateKeys = lastTags[key].dateKeys.slice(0,n).concat( lastTags[key].dateKeys.slice(n+1) );
            }
            if( lastTags[key].count>0 ){
                lastTags[key].date = lastTags[key].dateKeys[lastTags[key].count-1];
            } else {
                lastTags[key].date="";
            }
        }

        //profile
        let dateKeys = profile.dateKeys;
        if( dateKeys.includes(dateKey) ){
            const n = dateKeys.indexOf(dateKey);
            dateKeys = dateKeys.slice(0,n).concat(dateKeys.slice(n+1));
        }

        let firstDateKey = "";
        let lastDateKey = "";
        if( dateKeys.length>1 ){
            firstDateKey = dateKeys[0];
            lastDateKey = dateKeys[dateKeys.length-1];
        }       

        const newProfile = {...profile,dateKeys:dateKeys,firstDateKey:firstDateKey,lastDateKey:lastDateKey,tags:lastTags,version:profile.version+1};

        store.updateReportAndProfile(report.doc,report.id,newProfile,updates,fromDateKey,toDateKey,true,()=>{
            updateList();
        });

    }

    useEffect( ()=>{
        if(!userState.user){
            return;
        }
        if( !userState.hasProfile() ){
            store.getProfile(userState.user.uid,(profile:Profile)=>{
                userState.setProfile(profile);
                checkProfile(profile);
            },(error:any)=>{
                checkProfile();
            });
        } else {
            checkProfile(userState.getProfile());
        }
    },[userState.user]);

    if( !userState.ready ){
        return (null);
    }

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    const profile = userState.getProfile();

    return (
        <div id="report_list" className="smallRoot">
            <div className="reports">
                <h2>{profile.name}さんのニッキ</h2>
            {states.reports.map((report:Report,index:number)=>{
                const key = "report"+index;
                const to = "/report/input/"+report.id;
                const y = report.doc.dateKey.substr(0,4);
                const m = report.doc.dateKey.substr(4,2);
                const d = report.doc.dateKey.substr(6,2);
                return (
                    <div key={key} className="box">
                        <div className="date">
                            {m}月{d}日<br/>
                            <div className="year">{y}年</div>
                            <div className="remove">
                                <IconButton edge="end" aria-label="delete" size="small" onClick={removeReport} data-index={index}>
                                    <FontAwesomeIcon icon="trash-alt"/>
                                </IconButton>
                            </div>
                        </div>
                        <div className="text">
                            <div><MultiLines text={report.doc.memo}/></div>
                            <ul className="tags">
                            {Object.keys(report.doc.tags).map( (name:string,index:number)=>{
                                const key = "tag"+index;
                                const tag = report.doc.tags[name];
                                let buri = "";
                                if( tag.count>1 ){
                                    buri = String(tag.days) + "日ぶり";
                                }
                                return (
                                    <li key={key}>
                                        {name}（{buri}{tag.count}回目）
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                        <div className="btns">
                            <Link to={to} className="link"><IconButton edge="end" aria-label="edit">
                                <FontAwesomeIcon icon="edit"/>
                            </IconButton></Link>
                        </div>
                    </div>
                )
            })}
                {!states.isEnd &&
                <div className="more">
                    <Button variant="contained" color="primary" onClick={loadMore} style={{width:"100%"}}>もっと表示</Button>
                </div>
                }
            </div>
            <div className="search">
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl fullWidth={true}>
                            <FormLabel component="label" style={{marginBottom:"0.4em"}}>検索タグ</FormLabel>
                            <Button size="small" variant="contained" color="default" onClick={openTagPick}>選択</Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <ColInput name="" id="tag" type="text" icon="cancel" onIconClick={clearSearchTag} maxLength={20} placeholder="" value={states.searchTag} change={changeSearchTag}/>
                    </Grid>
                </Grid>
                <ColInput name="日付" id="date" type="date" value={states.searchDate} change={changeSearchDate}/>

                <div className="btns">
                    <Button variant="outlined" color="primary" onClick={search}>検索する</Button>
                </div>
            </div>
            <Yesno mes="削除します" yes={yes} no={no} isOpen={states.removeIndex>=0}/>
            <TagPick close={closeTagPick} add={setSearchTag} tags={profile.tags} isOpen={states.isTagPick}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default ReportList;
