import {useState} from 'react';
import firebase from 'firebase/app'
import {createContainer} from "unstated-next";
import {Inc,Profile} from "./Inc";

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
  
const useUserState = ()=> {
	const [user, setUser] = useState<firebase.User | null>(null);
	//const [data, setData] = useState<any | null>(null);
	const [profile, setProfile] = useState<Profile | null>(null);
    const [ready, setReady] = useState(false);

    firebase.auth().onAuthStateChanged(user => setUser(user));
	firebase.auth().onIdTokenChanged(id=>check(id));
	
    const check = (id:any)=>{
        //console.log("test",id);
        setReady(true);
	}
	
	//const setProfile = (profile:Profile | null)=>{
	//	setData({...data,profile:profile});
	//}

	const getProfile = ()=>{
		if(user && profile ){
			return profile;
		}
		return Inc.defaultProfile();
	}

	const hasProfile = ()=>{
		if( user && profile ){
			return true;
		} else {
			return false;
		}
	}

	const signIn = async (method:"twitter"|"google"|"yahoo"|"email",funcSuccess?:any,funcFailure?:any) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				const provider = new firebase.auth.GoogleAuthProvider();
				await firebase.auth().signInWithPopup(provider) && funcSuccess && funcSuccess();
			}
			if( method==="twitter" ){
				const provider = new firebase.auth.TwitterAuthProvider();
				await firebase.auth().signInWithPopup(provider) && funcSuccess && funcSuccess();
			}
			if( method==="yahoo" ){
				const provider = new firebase.auth.OAuthProvider('yahoo.com');
				await firebase.auth().signInWithPopup(provider) && funcSuccess && funcSuccess();
			}
			if( method==="email" ){
				await signInWithEmailLink(funcSuccess,funcFailure);
			}
		} catch (error) {
			setUser(null);
		}
	}

	const sendEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			firebase.auth().sendSignInLinkToEmail(email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const signInWithEmailLink = async (funcSuccess:any,funcFailure?:any)=>{
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				firebase.auth().signInWithEmailLink(email, window.location.href)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error) {
					console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	const updateEmail = async (email:string,funcSuccess?:any,funcFailure?:any)=>{
		const user = firebase.auth().currentUser;
		try {
			if( user ){
				await user.updateEmail(email);
				await user.getIdToken(true);
				await user.sendEmailVerification(updateMailSettings);
				funcSuccess && funcSuccess();
			} else {
				funcFailure && funcFailure();	
			}
		} catch (e) {
			console.log("error",e);
			funcFailure && funcFailure();			
		}
	}

	const signOut = async () => {
		//console.log("user",user);
		setProfile(null);
		setUser(null);
		await firebase.auth().signOut();
	}

	return { user, ready, signIn, signOut, sendEmail, updateEmail, setProfile, getProfile, hasProfile }
}
export const UserContainer = createContainer(useUserState);
