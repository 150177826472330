import {db} from './firebase';
import {ReportDoc,Report,Profile,ReportUpdate,ReportSearch} from './Inc';
import { Tools } from './Tools';

export default class store {
    static users = process.env.REACT_APP_FIREBASE_COLLECTION ? process.env.REACT_APP_FIREBASE_COLLECTION : "users";
    static getReport = (uid:string,id:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid).collection("reports").doc(id);
        ref.get().then((doc) => {
            if (doc.exists) {
                funcSuccess( doc.data() as ReportDoc );
            } else if(funcFailure){
                funcFailure();
            } else {
                //console.log("not found");
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
        });        
    }
    static getReports = (uid:string,endDateKey:string,search:ReportSearch,func:any)=>{
        let sa:"<"|"<=" = "<";
        if(endDateKey===""){
            if(search.date){
                endDateKey = Tools.getDateKey(search.date);
                sa = "<=";
            } else {
                endDateKey="99999999";
            }
        }
        if( search.tag!=="" ){
            db.collection(store.users).doc(uid).collection("reports").where("dateKey",sa,endDateKey).where("tagNames","array-contains",search.tag).orderBy('dateKey','desc').limit(10).get().then((response) => {
                let list:Report[] = [];
                response.forEach((doc) => {
                    list.push( {id:doc.id,doc:doc.data() as ReportDoc} );
                });
                func(list);
            });        
        } else {
            db.collection(store.users).doc(uid).collection("reports").where("dateKey",sa,endDateKey).orderBy('dateKey','desc').limit(10).get().then((response) => {
                let list:Report[] = [];
                response.forEach((doc) => {
                    list.push( {id:doc.id,doc:doc.data() as ReportDoc} );
                });
                func(list);
            });        
        }
    }
    static getRangeReports = (uid:string,fromDateKey:string,toDateKey:string,func:any)=>{
        db.collection(store.users).doc(uid).collection("reports").where("dateKey",">=",fromDateKey).where("dateKey","<=",toDateKey).orderBy('dateKey','asc').get().then((response) => {
            let list:Report[] = [];
            response.forEach((doc) => {
                list.push( {id:doc.id,doc:doc.data() as ReportDoc} );
            });
            func(list);
        });        
    }
    static addReport = (reportDoc:ReportDoc,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(reportDoc.userId).collection("reports").add(reportDoc)
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            }
        });
    }
    static updateReport = (reportDoc:ReportDoc,id:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(reportDoc.userId).collection("reports").doc(id);
        //console.log("update",id,reportDoc);
        ref.set(reportDoc).then(() => {
            funcSuccess();
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
            if(funcFailure){
                funcFailure();
            }
        });        
    }
    static removeReport = (uid:string,id:string,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).collection("reports").doc(id).delete()
        .then(() => {
            funcSuccess();
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            }
        });
    }
    static findReport = (uid:string,dateKey:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid).collection("reports").where("dateKey","==",dateKey).limit(1);
        ref.get().then((response) => {
            if( response.size>0 ){
                response.forEach((doc)=>{
                    funcSuccess( doc.id );
                });
            } else {
                if( funcFailure ){
                    funcFailure();
                }
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
            if( funcFailure ){
                funcFailure();
            }
        });        
    }
    static nextFirstReportDateKey = (uid:string,firstDateKey:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid).collection("reports").where("dateKey",">",firstDateKey).orderBy("dateKey","asc").limit(1);
        ref.get().then((response) => {
            if( response.size>0 ){
                response.forEach((doc)=>{
                    const report:ReportDoc = doc.data() as ReportDoc;
                    funcSuccess( report.dateKey );
                });
            } else {
                if( funcFailure ){
                    funcFailure();
                }
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
            if( funcFailure ){
                funcFailure();
            }
        });        
    }

    static setProfile = (uid:string,profile:Profile,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).set(profile)
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            } else {
                console.log(error);
            }
            
        });
    }
    static updateProfileParam = (uid:string,param:string,value:any,funcSuccess:any,funcFailure?:any)=>{
        db.collection(store.users).doc(uid).update({[param]:value})
        .then((response) => {
            funcSuccess();    
        })
        .catch( (error)=>{
            if(funcFailure){
                funcFailure();
            } else {
                console.log(error);
            }
            
        });
    }
    static getProfile = (uid:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).doc(uid)
        ref.get().then((doc) => {
            if (doc.exists) {
                funcSuccess( doc.data() as Profile );
            } else if(funcFailure){
                funcFailure();
            } else {
                console.log("not found");
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
        });        
    }
    static getPublicProfile = (publicKey:string,funcSuccess:any,funcFailure?:any)=>{
        const ref = db.collection(store.users).where("publicKey","==",publicKey).limit(1);
        ref.get().then((response) => {
            if(response.size>0){
                response.forEach((doc)=>{
                    funcSuccess( doc.id , doc.data() as Profile );
                });
            } else {
                if( funcFailure ){
                    funcFailure();
                }
            }
        })
        .catch( (error) => {
            console.log(`データを取得できませんでした (${error})`);
        });        
    }
    static addReportAndProfile = (reportDoc:ReportDoc,profile:Profile,updates:ReportUpdate[],fromDateKey:string,toDateKey:string,funcSuccess:any,funcFailure?:any)=>{
        const doBatch = (list?:Report[])=>{
            let batch = db.batch();
            const id = store.getUniqueId();
            const ref1 = db.collection(store.users).doc(reportDoc.userId).collection("reports").doc(id);
            batch.set(ref1,reportDoc);
            const ref2 = db.collection(store.users).doc(reportDoc.userId);
            batch.set(ref2,profile)

            if( list && updates.length>0 ){
                let flags:boolean[] = [];
                for( let i=0; i<updates.length; i++ ){
                    let count = updates[i].count;
                    let dateKey = updates[i].dateKey;
                    for( let j=0; j<list.length; j++ ){
                        if( i===0 ){
                            flags.push(false);
                        }
                        if( updates[i].dateKeys.includes(list[j].doc.dateKey) ){
                            flags[j] = true;
                            let report = list[j];
                            count++;
                            report.doc.tags[ updates[i].name ] = {
                                count: count,
                                days: Tools.calcDays(dateKey,report.doc.dateKey),
                            }
                            dateKey = report.doc.dateKey;
                            list[j] = report;
                        }
                    }
                }

                for( let i=0; i<flags.length; i++ ){
                    if( flags[i]===true ){
                        const ref3 = db.collection(store.users).doc(reportDoc.userId).collection("reports").doc(list[i].id);
                        batch.set( ref3, list[i].doc );
                    }
                }
            }

            return batch.commit()
            .then((response)=>{
                funcSuccess(response);
            })
            .catch( (error)=>{
                console.log(error);
                if( funcFailure ){
                    funcFailure();
                }
            });
        }
        if( fromDateKey!=="" && toDateKey!=="" ){
            store.getRangeReports(reportDoc.userId,fromDateKey,toDateKey,(list:Report[])=>{
                //console.log("list",list);
                doBatch(list);
            })
        } else {
            doBatch();
        }

    }
    static updateReportAndProfile = (reportDoc:ReportDoc,id:string,profile:Profile,updates:ReportUpdate[],fromDateKey:string,toDateKey:string,deleteFlag:boolean,funcSuccess:any,funcFailure?:any)=>{
        //console.log("update",updates,fromDateKey,toDateKey);
        const updateFirstDateKey = (func1:any,func2:any)=>{
            if( profile.firstDateKey==="search" ){
                store.nextFirstReportDateKey(reportDoc.userId,reportDoc.dateKey,(dateKey:string)=>{
                    profile.firstDateKey = dateKey;
                    func1();
                },()=>{
                    profile.firstDateKey = "";
                    func2();
                })
            } else {
                func1();
            }
        }

        const doBatch = (list?:Report[])=>{
            if( id==="" ){
                //add
                id = store.getUniqueId();
            }
            let batch = db.batch();
            const ref1 = db.collection(store.users).doc(reportDoc.userId).collection("reports").doc(id);
            if( deleteFlag ){
                batch.delete( ref1 );
            } else {
                batch.set( ref1 , reportDoc );
            }
            const ref2 = db.collection(store.users).doc(reportDoc.userId);
            batch.set(ref2,profile);

            if( list && updates.length>0 ){
                let flags:boolean[] = [];
                for( let i=0; i<updates.length; i++ ){
                    let count = updates[i].count;
                    let dateKey = updates[i].dateKey;
                    for( let j=0; j<list.length; j++ ){
                        if( i===0 ){
                            flags.push(false);
                        }
                        if( updates[i].dateKeys.includes(list[j].doc.dateKey) ){
                            flags[j] = true;
                            let report = list[j];
                            count++;
                            report.doc.tags[ updates[i].name ] = {
                                count: count,
                                days: Tools.calcDays(dateKey,report.doc.dateKey),
                            }
                            dateKey = report.doc.dateKey;
                            list[j] = report;
                        }
                    }
                }

                for( let i=0; i<flags.length; i++ ){
                    if( flags[i]===true ){
                        const ref3 = db.collection(store.users).doc(reportDoc.userId).collection("reports").doc(list[i].id);
                        batch.set( ref3, list[i].doc );
                    }
                }
            }

            return batch.commit()
            .then((response)=>{
                funcSuccess(response);
            })
            .catch( (error)=>{
                console.log(error);
                if( funcFailure ){
                    funcFailure();
                }
            });
        }

        updateFirstDateKey(()=>{
            if( fromDateKey && toDateKey && fromDateKey!=="" && toDateKey!=="" ){
                store.getRangeReports(reportDoc.userId,fromDateKey,toDateKey,(list:Report[])=>{
                    doBatch(list);
                })
            } else {
                doBatch();
            }
        },()=>{
            funcFailure && funcFailure();
        });
    }

    static getUniqueId(myStrong?: number): string {
        let strong = 10000;
        if (myStrong) strong = myStrong;
        return (
            ("0000" + Math.floor(strong * Math.random()).toString(16)).slice(-4) + ("00000000000" + new Date().getTime().toString(16)).slice(-11)
        );
    }
}