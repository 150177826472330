export const CookieHashName = "nikki19";
export const getCookieOptions = (domain:string | undefined)=>{
    //console.log("domain",domain);
    const expire = new Date();
    expire.setDate(expire.getDate() + 20);
    const options = {
        path: "/",
        expires: expire,
        maxAge: 86400*20,
        domain: domain,
        secure: false,
        httpOnly: false,
        sameSite: true,
    }
    return options;
};

export interface Dataset {
    id: string,
    label: string,    
}
export interface Profile {
    name: string,
    dateKeys: string[],
    tags: ReportLastTags,
    version: number,
    secret: string,
    firstDateKey: string,
    lastDateKey: string,
    publicKey: string,
}
export interface Report {
    id: string,
    doc: ReportDoc,
}
export interface ReportDoc {
    userId: string,
    memo: string,
    tags: ReportTags,
    tagNames: string[],
    date: string,
    dateKey: string,
    createdAt: Date,
    updatedAt: Date,
}
export interface ReportTag {
    days: number,
    count: number,
}
export interface ReportLastTag {
    date: string,
    count: number,
    dateKeys: string[],
    start: number,
}
export interface ReportInputErrors {
    memo: string,
    date: string,
    tag: string,
}
export interface ReportUpdate {
    name: string,
    count: number,
    dateKey: string,
    dateKeys: string[],
}
export interface ReportSearch {
    tag: string,
    date: string,
}
export type ReportTags = {[key: string]: ReportTag};
export type ReportLastTags = {[key: string]: ReportLastTag};

export class Inc {
    static readonly secrets:Dataset[] = [
        {
            id:     "0",
            label:  "自分だけ見られる",
        },
        {
            id:     "1",
            label:  "誰でも見られる",
        },
    ];
    static readonly defaultProfile = ()=>{
        let profile:Profile = {
            name: "",
            dateKeys: [],
            tags: {},
            version: 0,
            firstDateKey: "",
            lastDateKey: "",
            secret: "0",
            publicKey: "",
        };
        return profile;
    }
    static readonly defaultReportDoc = ()=>{
        let defTags: ReportTags = {};
        let reportDoc:ReportDoc = {
            memo: "",
            tags: defTags,
            tagNames: [],
            date: "",
            dateKey: "",
            userId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
        };
        return reportDoc;
    }
    static readonly defaultReportTag = ()=>{
        let tag:ReportTag = {
            days: 0,
            count: 0,
        };
        return tag;
    }
    static readonly defaultReportInputErrors = ()=>{
        let errors:ReportInputErrors = {
            memo: "",
            date: "",
            tag: "",
        }
        return errors;
    }


}
