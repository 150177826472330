import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {Tools} from './Tools';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
import Toast from './Toast';
import {Inc} from './Inc';

interface Props {
    close():void,
    name:string,
    secret:string,
    update(secret:string):void,
    isOpen:boolean,
}

const ProfileSecret = (props:Props)=> {
    const [states,setStates] = useState({
        secret: "",
    })
    const [toast,setToast] = useState("");
    
    const change = (name:"secret",value:string)=>{
        setStates({...states,secret:value});
    }

    const update = ()=>{
        const value = states.secret.trim();
        if(value===""){
            setToast("新しい公開設定を選んでください");
            return;
        }

        props.update(value);
    }
    const closeToast = ()=>{
        setToast("");
    }

    useEffect(()=>{
        setStates({...states,secret:""});
    },[]);

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="tagPicker">
                <ModalHeader close={props.close}/>
                <div className="dialogRoot">
                    <h2>{props.name}</h2>
                    <FormControl fullWidth={true}>
                        <FormLabel component="label" className="formLabel">現在の公開設定</FormLabel>
                        <div className="colData">
                            <div className="value">{Tools.getLabel(Inc.secrets,props.secret)}</div>
                        </div>
                    </FormControl>

                    <ColInput id="name" type="select" name="新しい公開設定" list={Inc.secrets} value={states.secret} change={change}/>
                    <Button variant="contained" color="primary" onClick={update} style={{width:"100%"}}>変更する</Button>
                </div>
                <Toast close={closeToast} mes={toast}/>
            </div>
        </Modal>
    );
}

export default ProfileSecret;
