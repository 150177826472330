import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import About from './About';

const First = ()=> {
    const [about,setAbout] = useState<"company"|"warning"|"copyright"|"">("");

    const openAbout = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.type ){
			return;
        }
        if( e.currentTarget.dataset.type==="company"){
            setAbout("company");
        }
        if( e.currentTarget.dataset.type==="copyright"){
            setAbout("copyright");
        }
        if( e.currentTarget.dataset.type==="warning"){
            setAbout("warning");
        }
    }
    const closeAbout = ()=>{
        setAbout("");
    }
    return (
        <div id="first" className="smallRoot">
            <div className="paper">
                <div className="first">
                    <h2>あれから何年経ったのかしら？</h2>
                    <p>Peterは「何日ぶり何回目」に特化した簡易日記ツールです。</p>
                    <p>サンプルをご覧になって興味が沸いたら、注意事項をご一読の上でご利用ください。</p>
                    <div className="sample">
                        <Link to="/sample" className="link"><Button variant="outlined" color="primary">サンプルを見る</Button></Link>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <Button variant="text" onClick={openAbout} data-type="company">運営会社</Button>
                <Button variant="text" onClick={openAbout} data-type="copyright">著作権等</Button>
                <Button variant="text" onClick={openAbout} data-type="warning">注意事項</Button>
            </div>
            <About type={about} isOpen={about!==""} close={closeAbout}/>
        </div>
    );
}

export default First;
