import React from 'react';
import Modal from '@material-ui/core/Modal';
import {Tools} from './Tools';
import ModalHeader from './ModalHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReportLastTags} from './Inc';
//import useStyles from './Styles';


interface Props {
    close():void,
    add(name:string):void,
    tags:ReportLastTags,
    isOpen:boolean,
}

const TagPick = (props:Props)=> {
    const pick = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.name ){
			return;
        }
        props.add(e.currentTarget.dataset.name);
        //props.close();
    }
    let n = 0;
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalPickerStyle()} className="tagPicker">
                <ModalHeader close={props.close}/>
                <div className="pickerRoot">

            <div className="reportTags" style={{position:"absolute",paddingTop:"3em",top:"0",maxHeight:"96%",overflowY:"auto",boxSizing:"border-box"}}>
                {Object.keys(props.tags).map( (name:string,index:number)=>{
                    const key = "tag"+index;
                    n++;
                    return (
                        <div className="tag selectable" key={key} onClick={pick} data-name={name}>
                            <FontAwesomeIcon icon="tag"/> <span>{name}</span>
                        </div>
                    )
                })}
                {n===0 &&
                <span>選択できるタグはありませぬ</span>
                }
            </div>
                    
                </div>
            </div>
        </Modal>
    );
}

export default TagPick;
