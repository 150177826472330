import React,{useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import DatePick from './DatePick';
import {Dataset} from './Inc';

interface Props {
    name: string,
    value?: string,
    error?: string,
    label?: string,
    id: string,
    list?: Dataset[],
    type: "text" | "textarea" | "select" | "radio" | "date" | "switch",
    required?:boolean,
    fullWidth?:boolean,
    startAdornment?:string,
    endAdornment?:string,
    notEmpty?:boolean,
    helper?:string,
    maxLength?:number,
    minLength?:number,
    maxRange?:number,
    minRange?:number,
    rows?:number,
    dense?:boolean,
    placeholder?:string,
    icon?:string,
    noName?:boolean,
    change(name:string,value:string): void,
    addError?(name:string,flag:boolean): void,
    blur?(): void,
    focus?(): void,
    onIconClick?(): void,
}

const ColInput:React.FC<Props> = (props)=> {
    let error = "";
    let value = "";
    let clicked = false;
    if( props.value ){
        value = props.value;
    }

    const [states,setStates] = useState({
        isCalendar: false,
    });

	const changeInput = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        //const isError = validate();
        props.change(name,event.target.value);
    };
    const changeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        //setAge(event.target.value as string);
        props.change(props.id,event.target.value as string);
    };    
	const changeRadio = (name:string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        //const isError = validate();
		props.change(name,event.target.value);
    };
    const changeSwitch = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if( event.target.checked ){
            props.change(name,"1");
        } else {
            props.change(name,"0");
        }
    };    
    const changeDate = (dt:string) => {
        props.change(props.id,dt);
        setStates({...states,isCalendar:false});
    }
    const blur = ()=>{
        if( props.blur ){
            props.blur();
        }
    }
    const focus = ()=>{
        if( props.focus ){
            props.focus();
        }
    }
    const openCalendar = ()=>{
        if(clicked){
            clicked = false;
            return;
        }
        setStates({...states,isCalendar:true});
    }
    const closeCalendar = ()=>{
        setStates({...states,isCalendar:false});
    }
    const validate = ()=>{
        error = "";
        if( props.error ){
            error = props.error;
            //console.log("error",error);
        }
        if( required && (!value || value==="") ){
            error = "省略できません";
        }
        if( props.maxRange || props.maxRange===0 ){
            if( value ){
                if( parseInt(value) > props.maxRange ){
                    error = String(props.maxRange) + "以下にして下さい";
                }
            }
        }
        if( props.minRange || props.minRange===0 ){
            if( value ){
                if( parseInt(value) < props.minRange ){
                    error = String(props.minRange) + "以上にして下さい";
                }        
            }
        }
        if( props.maxLength || props.maxLength===0 ){
            if( value ){
                if( value.length > props.maxLength ){
                    error = String(props.maxLength) + "文字以内にして下さい";
                }
            }
        }
        if( props.minLength || props.minLength===0 ){
            if( value ){
                if( value.length < props.minLength ){
                    error = String(props.minLength) + "文字以上にして下さい";
                }
            }
        }
        if( props.type==="date" && value!=="" ){
            //if(!value.match(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/)){
            //    error = "存在する日付を入力して下さい";
            //}
        }
        if( error !=="" ){
            if( props.addError ){
                props.addError(props.id,true);
            }
            return true;
        } else {
            if( props.addError ){
                props.addError(props.id,false);
            }
            return false;
        }
    }
    const clickIcon = ()=>{
        if( props.onIconClick ){
            clicked = true;
            props.onIconClick();
        }
    }



    let required = false;
    if( props.required && props.required===true ){
        required = true;
    }

    let notEmpty = false;
    if( required ){
        notEmpty = true;
    } else if( props.notEmpty && props.notEmpty===true ){
        notEmpty = true;
    }

    let inputProps = {};
    if( props.startAdornment && props.endAdornment ){
        inputProps = {
            endAdornment: <InputAdornment style={{whiteSpace:"nowrap"}} position="end">{props.endAdornment}</InputAdornment>,
            startAdornment: <InputAdornment style={{whiteSpace:"nowrap"}} position="start">{props.startAdornment}</InputAdornment>,
        }
    } else if( props.endAdornment ){
        inputProps = {
            endAdornment: <InputAdornment style={{whiteSpace:"nowrap"}} position="end">{props.endAdornment}</InputAdornment>,
        }
    } else if( props.startAdornment ){
        inputProps = {
            startAdornment: <InputAdornment style={{whiteSpace:"nowrap"}} position="start">{props.startAdornment}</InputAdornment>,
        }
    } else if( props.icon ){
        let icon = <AddIcon/>
        if( props.icon==="cancel" ){
            icon = <CancelIcon/>
        }
        inputProps = {
            endAdornment: 
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={clickIcon}
                >
                    {icon}
                </IconButton>
              </InputAdornment>
            ,
        }
    }

    let name = "　";
    if( props.name ){
        name = props.name;
    }

    let rows = 3;
    if( props.rows ){
        rows = props.rows;
    }

    let style = {marginBottom:"1.5em"};
    if( props.dense ){
        style = {marginBottom:"0em"};
    }

    let noName = false;
    if( props.noName ){
        noName = true;
    }

    validate();

    return (
    <React.Fragment>
        <FormControl fullWidth={true} required={required} error={error!==""} style={style}>
            {!noName && <FormLabel component="label" className="formLabel">{name}</FormLabel>}
            {props.type==="text" &&
            <TextField
                id={props.id}
                value={value}
                onChange={changeInput(props.id)}
                onBlur={blur}
                onFocus={focus}
                margin="dense"
                InputProps={inputProps}
                placeholder={props.placeholder}
                //variant="outlined"
            />
            }

            {props.type==="date" &&
            <TextField
                id={props.id}
                value={value}
                onChange={changeInput(props.id)}
                onBlur={blur}
                onFocus={openCalendar}
                onClick={openCalendar}
                margin="dense"
                placeholder={props.placeholder}
                InputProps={inputProps}
                //variant="outlined"
            />
            }

            {props.type==="select" &&
                <Select
                    native
                    value={value}
                    onChange={changeSelect}
                    inputProps={{
                        name: props.id,
                        id: props.id,
                    }}
                    margin="none"
                    style={{marginTop:"0.33em"}}
                    id={props.id}
                >
                    {!notEmpty &&
                    <option key="option0" value=""></option>
                    }
                    {props.list && 
                        props.list.map((option:Dataset,index:number) => {
                            const key = "option"+index;
                            return (
                                <option key={key} value={option.id}>
                                    {option.label}
                                </option>
                            )
                        })
                    }
                </Select>
            }


            {props.type==="textarea" &&
            <TextField
                id={props.id}
                value={value}
                onChange={changeInput(props.id)}
                margin="dense"
                rows={rows}
                //variant="outlined"
                multiline
            />
            }

            {props.type==="radio" &&
			<RadioGroup
                //className={classes.radiogroup}
                value={value}
                row
            >
                {props.list &&
                    props.list.map((data:Dataset,index:number)=>{
                    const key = "radio"+index;
                    return (
                        <FormControlLabel key={key} value={data.id} control={<Radio color="primary" onChange={changeRadio(props.id)}/>} label={data.label} />
                    );
                })}
			</RadioGroup>
            }

            {props.type==="switch" &&
            <FormControlLabel
                control={
                <Switch
                    checked={value==="1"}
                    onChange={changeSwitch(props.id)}
                    value="1"
                    color="primary"
                />
                }
                label={props.label}
            />
            }

            {props.helper && error==="" &&
            <FormHelperText>※{props.helper}</FormHelperText>
            }
            {error!=="" &&
            <FormHelperText>※{error}</FormHelperText>
            }
        </FormControl>
        <DatePick isOpen={states.isCalendar} close={closeCalendar} change={changeDate} value={value}/>
    </React.Fragment>
    );
}

export default ColInput;
