import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Fab from '@material-ui/core/Fab';

const ClimbUp = ()=> {
    const up = ()=>{
        window.scrollTo(0,0);
    }
    return (
        <div style={{position:"fixed",right:"0.5em",bottom:"0.5em"}}>
            <Fab color="secondary" size="small" onClick={up} style={{color:"#FFFFFF"}}><ArrowUpwardIcon/></Fab>
        </div>
    )
}

export default ClimbUp;