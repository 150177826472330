import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Theme} from './Theme';
import {Dataset} from './Inc';

export class Tools {
    static getWindowSize = ()=>{
        let wd = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        w = wd.innerWidth || e.clientWidth || g.clientWidth,
        h = wd.innerHeight|| e.clientHeight|| g.clientHeight;
    
        return {
            width: w,
            height: h
        }
    }
    static isWidthDown = (num:number)=>{
        const size = Tools.getWindowSize();
        if( size.width<num ){
            return true;
        }
        return false;
    }
    static getModalStyle = ()=> {
        const top = 50;
        const left = 50;
        const size = Tools.getModalSize();
      
        return {
            top: `${top}%`,
            left: `${left}%`,
            maxHeight: `${size.height}px`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    static getModalSearchStyle = ()=>{
        const size = Tools.getModalSize();
        return {
            width: `${size.width}px`,
            height: `${size.height}px`,
            backgroundColor: `white`,
        };
    }
    static getModalConfirmStyle = ()=>{
        const size = Tools.getModalSize();
        return {
            width: `${size.width}px`,
            height: `${size.height}px`,
        };
    }
    static getModalEditorStyle = ()=>{
        const size = Tools.getModalSize(true);
        return {
            width: `${size.width}px`,
            height: `${size.height}px`,
        };
    }
    static getModalPickerStyle = ()=>{
        const size = Tools.getModalSize();
        return {
            top: `50%`,
            left: `50%`,
            width: `${size.width}px`,
            height: `${size.height}px`,
            transform: `translate(-50%, -50%)`,
        };
    }
    static getModalSize = (wide?:boolean)=>{
        const size = Tools.getWindowSize();
        let w = 800;
        let max = 0.9;
        if( wide ){
            w = 960;
            max = 0.95;
        }
        if( size.width<800 ){
            w = size.width;
            max = 1.0;
        }
        let h = Math.floor(size.height * max);
        if( h>800 && !wide ){
            h = 800;
        }
        //console.log(w,h);
        return {
            width: w,
            height: h,
        }
    }
    static decodeQueryStrings(str:string){
        const sp1 = str.replace("?","").split("&");
        let data:{[key: string]: string;} = {};
        for(let i=0 ; i<sp1.length ; i++){
            const sp2 = sp1[i].split("=");
            data[ sp2[0] ] = sp2[1];
        }
        return data;
    }
    static pager = (param:any,func:any)=>{
        if(!param){
            return (null);
        }
        const style = {
            backgroundColor: "white",
            border:"1px solid silver",
            width:"3em",
            padding:"0.25em 0",
            margin:"0 0.1em",
            cursor:"pointer",
        }
        const style_current = {
            backgroundColor: Theme.palette.primary.main,
            border:`1px solid ${Theme.palette.primary.main}`,
            width:"3em",
            padding:"0.25em 0",
            margin:"0 0.1em",
            color:"white",
        }
        let tmp = [];
        if( param.start>param.min_page ){
            tmp.push(
                (<button style={style} key="pgfirst" onClick={()=>func(param.min_page)}>&lt;</button>)
            );
        }
        for( let i=param.start ; i<=param.end ; i++ ){
            const key = "pg"+i;
            if( i!==parseInt(param.current) ){
                tmp.push(
                    (<button style={style} key={key} onClick={()=>func(i)}>{i}</button>)
                );
            } else {
                tmp.push(
                    (<button style={style_current} key={key} onClick={()=>func(i)}>{i}</button>)
                );
            }
        }
        if( param.end<param.max_page ){
            tmp.push(
                (<button style={style} key="pglast" onClick={()=>func(param.max_page)}>&gt;</button>)
            );
        }

        return (
            <Grid container spacing={0}>
                <Grid item sm={10} xs={12}>{tmp}</Grid>
                <Grid item sm={2} xs={12} style={{textAlign:"right"}}><span style={{borderBottom:"1px solid silver"}}>{param.count}件</span></Grid>
            </Grid>
        );
    }
    static getLabel = (list:Dataset[],id:string) => {
        for( let i=0 ; i<list.length ; i++ ){
            if( list[i].id===id ){
                return list[i].label;
            }
        }
        return "";
    }
    static zeroPadding = (num:string,length:number)=>{
        return ('0000000000' + num).slice(-length);
    }
    static comma = (num:number) =>{
        return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    static joinTime = (h:string,m:string)=>{
        if( h!=="" && m!=="" ){
            return Tools.zeroPadding(h,2)+":"+Tools.zeroPadding(m,2);
        }
        return "";
    }
    static getSelectYears = ()=>{
        let years:Dataset[] = [];
        const dt = new Date();
        const current = dt.getFullYear();
        for( let year=current ; year>=2005 ; year-- ){
            years.push({
                id: String(year),
                label: String(year)+"年",
            })
        }
        return years;
    }
    static getSelectMonths = ()=>{
        let months:Dataset[] = [];
        for( let month=1 ; month<=12 ; month++ ){
            months.push({
                id: String(month),
                label: String(month)+"月",
            })
        }
        return months;
    }
    static getStoreImg = (owner_id:number,img_id:number)=>{
        //console.log("process",process.env);
        return process.env.REACT_APP_STOREURL + "/img/owner/"+String(owner_id)+"/"+String(img_id)+".jpg";
    }
    static toHan = (strVal:string)=>{
        // 半角変換
        var halfVal = strVal.replace(/[！-～]/g,
          function( tmpStr ) {
            // 文字コードをシフト
            return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
          }
        );
       
        // 文字コードシフトで対応できない文字の変換
        return halfVal.replace(/”/g, "\"")
          .replace(/’/g, "'")
          .replace(/‘/g, "`")
          .replace(/￥/g, "\\")
          .replace(/　/g, " ")
          .replace(/〜/g, "~");
    }
    static isNumber = (numVal:string)=>{
        const pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
        return pattern.test(numVal);
    }
    static colorLabel = (type:string,id:number|string,text:string|undefined)=>{
        if(!text){
            return (null);
        }
        const cl = "cl cl-" + type + id;
        return (
            <span className={cl}>{text}</span>
        )
    }

    static getDateKey = (date?:string)=>{
        let sp:number[] = [0,0,0];
        if( date ){
            const dts = date.split(/[-/]/);
            if( dts.length===3 ){
                sp[0] = parseInt(dts[0]);
                sp[1] = parseInt(dts[1]);
                sp[2] = parseInt(dts[2]);
            }
        } else {
            const dt = new Date();
            sp[0] = dt.getFullYear();
            sp[1] = dt.getMonth()+1;
            sp[2] = dt.getDate();
        }
        if( sp[0]>2000 && sp[0]<2100 && sp[1]>0 && sp[1]<13 && sp[2]>0 && sp[2]<32 ){
            return String(sp[0]) + ('0' + String(sp[1])).slice(-2) + ('0' + String(sp[2])).slice(-2);
        }
        return "";
    }
    static splitDateKey = (dateKey:string)=>{
        return [dateKey.substr(0,4),dateKey.substr(4,2),dateKey.substr(6,2)];
    }
    static calcDays = (fromDateKey:string,toDateKey:string)=>{
        if( fromDateKey==="" || toDateKey==="" ){
            return 0;
        }
        const sp1 = Tools.splitDateKey(fromDateKey);
        const d1 = new Date(sp1[0]+"/"+sp1[1]+"/"+sp1[2]);
        const sp2 = Tools.splitDateKey(toDateKey);
        const d2 = new Date(sp2[0]+"/"+sp2[1]+"/"+sp2[2]);
        return Math.round((d2.getTime() - d1.getTime())/86400000);
    }
    static isValidDate = (date:string)=>{
        const sp = date.split(/[-/]/)
        if( sp.length===3 ){
            const y = parseInt(sp[0]);
            const m = parseInt(sp[1])-1;
            const d = parseInt(sp[2]);
            const dt = new Date(y,m,d);
            if( dt.getMonth()===m ){
                return true;
            }
        }
        return false;
    }

}