import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'//yarn add @types/react-router-dom
import {MuiThemeProvider} from '@material-ui/core/styles';
import TopBar from "./TopBar";
import Main from "./Main";
import Login from "./Login";
import Enter from "./Enter";
import ProfileNew from './ProfileNew';
import ReportInput from './ReportInput';
import ReportList from './ReportList';
import TagList from './TagList';
import ProfileDetail from './ProfileDetail';
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTimesCircle,faTrashAlt,faSearch,faEdit,faCog,faTag,faTags,faUserCircle,faBars,faEnvelope,faCopyright,faUserLock,faClipboard,faExternalLinkSquareAlt} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {Theme} from './Theme';
import "./App.scss";
import {UserContainer} from './UserContainer';

const App: React.FC = () => {
  const basename = process.env.REACT_APP_BASENAME;
  //const [redirectTo,setRedirectTo] = useState("");
  library.add(faTimesCircle,faTrashAlt,faSearch,faEdit,faCog,faTag,faTags,faBars,faUserCircle,faEnvelope,faCopyright,faUserLock,faClipboard,faExternalLinkSquareAlt,fab);

  return (
    <BrowserRouter basename={basename}>
      <MuiThemeProvider theme={Theme}>
        <UserContainer.Provider>
          <TopBar/>
          <div className="App">
            <Switch>
              <Route exact path='/' render={()=><Main/>}/>
              <Route exact path='/login' render={()=><Login/>}/>
              <Route exact path='/profile/new' render={()=><ProfileNew/>}/>
              <Route exact path='/report/new' render={()=><ReportInput new={true}/>}/>
              <Route exact path='/report/input/:id' render={()=><ReportInput new={false}/>}/>
              <Route exact path='/report/list' render={()=><ReportList/>}/>
              <Route exact path='/tags' render={()=><TagList/>}/>
              <Route exact path='/profile' render={()=><ProfileDetail/>}/>
              <Route exact path='/mail/new' render={()=><MailNew/>}/>
              <Route exact path='/mail/update' render={()=><MailUpdate/>}/>
              <Route exact path='/:gid' render={()=><Enter/>}/>
            </Switch>
          </div>
        </UserContainer.Provider>      
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
