import React,{useState, useEffect} from 'react';
import {RouteComponentProps, Redirect} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
//import MultiLines from './MultiLines';
import store from "./store";
import ColInput from './ColInput';
import Loading from './Loading';
import Toast from './Toast';
import TagPick from './TagPick';
import {Tools} from './Tools';
import {UserContainer} from './UserContainer';
import {Inc,Profile,ReportDoc,ReportTag,ReportTags,ReportLastTags,ReportUpdate} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Yesno from './Yesno';

interface Props {
    new:boolean,
}

interface RCProps extends RouteComponentProps<{id:string}> {
	
}

const ReportInput = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const rcp:RCProps = useReactRouter();
    const defRemoveTags:string[] = [];
    const [states,setStates] = useState({
        id:"",
        report:Inc.defaultReportDoc(),
        tag: "",
        profile: Inc.defaultProfile(),
        removeTags: defRemoveTags,
        redirect:false,
        edited:false,
        isTagPick:false,
        isLoading:true,
        yesnoId:"",
    })
    const [vali,setVali] = useState({
        errors:Inc.defaultReportInputErrors(),
        message:"",
    });

    const changeReport = (name:keyof ReportDoc,value:string)=>{
        if( name==="date" ){
            if( Tools.isValidDate(value) ){
                const dateKey = Tools.getDateKey(value);
                if( dateKey!=="" ){
                    isUsedDate(dateKey,()=>{
                        setVali({...vali,message:value+"の日記はもう書いたからダメ"});
                    },()=>{
                        setStates({...states,report:{...states.report,[name]:value}});
                    });
                    return;
                }    
            }
        }
        setStates({...states,edited:true,report:{...states.report,[name]:value}});       
    }

    const loadReport = (uid:string,id:string)=>{
        setStates({...states,isLoading:true});
        let report:ReportDoc;
        let profile:Profile;
        store.getReport(uid,id,(res:ReportDoc)=>{
            //console.log("getReport");
            report = res;
            if(profile){
                set();
            }
        });
        loadProfile( (res:Profile)=>{
            //console.log("loadProfile");
            profile = res;
            if(report){
                set();
            }
        });
        const set = ()=>{
            setStates({...states,id:id,report:report,profile:profile,isLoading:false});      
        }
    }

    const validate = ()=>{
        if( !userState.user ){
            return false;
        }
        let errors = Inc.defaultReportInputErrors();

        if( states.report.date==="" ){
            errors.date = "日付が必要です";
            setVali({errors:errors,message:"日付を入力してください"});
            return;
        } else if(!Tools.isValidDate(states.report.date)){
            errors.date = "存在しない日付です";
            setVali({errors:errors,message:"日付を修正してください"});
            return;
        }
        if(states.tag!==""){
            errors.tag = "タグが追加されていません";
            setVali({errors:errors,message:"タグを追加または削除してください"});
            return;
        }
        if(states.report.memo.length>100){
            setVali({errors:errors,message:"特記事項が長すぎます"});
            return;
        }

        window.scroll(0,0);
        setStates({...states,isLoading:true});

        //更新バージョンをチェック
        store.getProfile(userState.user.uid,(profile:Profile)=>{
            if( profile.version!==states.profile.version ){
                setVali({errors,message:"登録できませんでした"});
            } else {
                saveReportAndProfile();
            }
        },()=>{
            setVali({errors,message:"登録できませんでした"});
        });
    }

    const saveReportAndProfile = ()=>{
        if( !userState.user ){
            return false;
        }

        const dateKey = Tools.getDateKey(states.report.date);

        //lastTagsの更新
        let lastTags:ReportLastTags = states.profile.tags;
        let lastCounts:{[name: string]: ReportTag} = {};
        let fromDateKey = "";
        let toDateKey = "";
        let updates:ReportUpdate[] = [];
        for (var key in states.report.tags) {
            lastCounts[ key ] = {
                count: 0,
                days: 0,
            }   
            if( lastTags[ key ] ){
                //既存のタグを使用
                if( lastTags[key].date<dateKey ){
                    lastTags[ key ].count++;
                    lastTags[ key ].date = dateKey;
                    lastTags[ key ].dateKeys.push(dateKey);
                    lastTags[ key ].dateKeys.sort();

                    lastCounts[ key ].count = lastTags[key].count;
                    if( lastTags[ key ].dateKeys.length>1 ){
                        lastCounts[ key ].days = Tools.calcDays(lastTags[ key ].dateKeys[ lastTags[ key ].dateKeys.length-2 ],dateKey);
                    } else {
                        lastCounts[ key ].days = 0;
                    }
                }
                if( lastTags[key].date===dateKey ){
                    //変更なし
                }
                if( lastTags[key].date>dateKey && !lastTags[ key ].dateKeys.includes(dateKey) ){
                    //dateKey以降、lastTags[key].dateまでのreportは更新が必要
                    lastTags[ key ].count++;
                    lastTags[ key ].dateKeys.push(dateKey);
                    lastTags[ key ].dateKeys.sort();
                    const n = lastTags[key].dateKeys.indexOf(dateKey);
                    const targetKeys = lastTags[key].dateKeys.slice(n+1);//更新対象のdateKey
                    if( n===0 ){
                        lastCounts[ key ] = {
                            days: 0,
                            count: 1,
                        }
                    } else {
                        lastCounts[ key ] = {
                            days: Tools.calcDays(lastTags[key].dateKeys[n-1],dateKey),
                            count: lastTags[ key ].count - targetKeys.length,
                        }
                    }

                    //targetKeysのreportを、lastCounts[key]を起点に更新していく
                    if( fromDateKey==="" || fromDateKey>targetKeys[0] ){
                        fromDateKey = targetKeys[0];
                    }
                    if( toDateKey==="" || toDateKey<targetKeys[targetKeys.length-1] ){
                        toDateKey = targetKeys[targetKeys.length-1];
                    }
                    updates.push({
                        name: key,
                        count: lastCounts[ key ].count,
                        dateKey: dateKey,
                        dateKeys: targetKeys,
                    });
                }
            } else {
                //新しいタグを追加
                lastTags[ key ] = {
                    date: dateKey,
                    count: 1,
                    dateKeys: [dateKey],
                    start: 1,
                }
                lastCounts[ key ] = {
                    count: 1,
                    days: 0,
                }   
    
            }
        }
        for (var i=0; i<states.removeTags.length; i++){
            const key = states.removeTags[i];
            if( !states.report.tags[key] && lastTags[key] ){
                const n = lastTags[key].dateKeys.indexOf(dateKey);

                const targetKeys = lastTags[key].dateKeys.slice(n+1);
                //console.log(targetKeys);
                if( fromDateKey==="" || fromDateKey>targetKeys[0] ){
                    fromDateKey = targetKeys[0];
                }
                if( toDateKey==="" || toDateKey<targetKeys[targetKeys.length-1] ){
                    toDateKey = targetKeys[targetKeys.length-1];
                }

                if( lastTags[key].dateKeys[n-1] ){
                    updates.push({
                        name: key,
                        count: lastTags[key].count - targetKeys.length - 1,
                        dateKey: lastTags[key].dateKeys[n-1],
                        dateKeys: targetKeys,
                    });
                } else {
                    updates.push({
                        name: key,
                        count: 0,
                        dateKey: "",
                        dateKeys: targetKeys,
                    });
                }

                lastTags[key].count--;
                if( n===0 ){
                    if(lastTags[key].dateKeys.length>1){
                        lastTags[key].dateKeys = lastTags[key].dateKeys.slice(1);
                    } else {
                        lastTags[key].dateKeys = [];
                    }
                } else {
                    lastTags[key].dateKeys = lastTags[key].dateKeys.slice(0,n).concat( lastTags[key].dateKeys.slice(n+1) );
                }
                if( lastTags[key].count>0 ){
                    lastTags[key].date = lastTags[key].dateKeys[lastTags[key].count-1];
                } else {
                    lastTags[key].date="";
                }
            }
        }

        //report.tagsの更新
        let tags:ReportTags = {};
        for( const name in states.report.tags ){
            let tag = states.report.tags[name];
            if( lastTags[name] && lastCounts[name] ){
                if( lastCounts[name].count>0 ){
                    tag = lastCounts[name];
                }
            } else {
                //念のため
                tag.count = 0;
                tag.days = 0;
            }
            tags[name] = tag;
        }

        //profile
        const profile = userState.getProfile();
        let dateKeys = profile.dateKeys;
        if( !dateKeys.includes(dateKey) ){
            dateKeys.push( dateKey );
            dateKeys.sort();
        }

        //重複の確認用に1年以内のキーだけ保存する
        //※1年より前の日付が使用される場合はその都度検索してチェックする
        const baseKey = getBaseDateKey();

        let n = 0;
        for( let i=0; i<dateKeys.length; i++ ){
            if( dateKeys[i]<baseKey ){
                n = i+1;
            }
        }
        if( n>0 ){
            dateKeys = dateKeys.slice(n);
        }

        let firstDateKey = profile.firstDateKey;
        let lastDateKey = "";
        if( firstDateKey==="" || firstDateKey>dateKey ){
            firstDateKey = dateKey;
        }
        if( dateKeys.length>1 ){
            //firstDateKey = dateKeys[0];
            lastDateKey = dateKeys[dateKeys.length-1];
        }       

        const newProfile = {...profile,dateKeys:dateKeys,firstDateKey:firstDateKey,lastDateKey:lastDateKey,tags:lastTags,version:profile.version+1};

        if( states.id==="" ){
            store.updateReportAndProfile({...states.report,tags:tags,tagNames:Object.keys(tags),userId:userState.user.uid,dateKey:dateKey,createdAt:new Date(),updatedAt:new Date()},"",newProfile,updates,fromDateKey,toDateKey,false,()=>{
                if( newProfile.firstDateKey!==profile.firstDateKey ){
                    userState.setProfile(newProfile);
                }
                setStates({...states,report:Inc.defaultReportDoc(),removeTags:defRemoveTags,profile:newProfile,isLoading:false,redirect:true});
            });
        } else {
            store.updateReportAndProfile({...states.report,tags:tags,tagNames:Object.keys(tags),dateKey:dateKey,updatedAt:new Date()},states.id,newProfile,updates,fromDateKey,toDateKey,false,()=>{
                setStates({...states,report:Inc.defaultReportDoc(),removeTags:defRemoveTags,profile:newProfile,isLoading:false,redirect:true});
            });
        }
    }

    const remove = ()=>{
        setStates({...states,isLoading:true});

        const profile = userState.getProfile();
        const report = states.report;

        let lastTags:ReportLastTags = profile.tags;
        let fromDateKey = "";
        let toDateKey = "";
        let updates:ReportUpdate[] = [];
        const dateKey = report.dateKey;
        for( var key in report.tags ){
            const n = lastTags[key].dateKeys.indexOf(dateKey);
            const targetKeys = lastTags[key].dateKeys.slice(n+1);

            if( fromDateKey==="" || fromDateKey>targetKeys[0] ){
                fromDateKey = targetKeys[0];
            }
            if( toDateKey==="" || toDateKey<targetKeys[targetKeys.length-1] ){
                toDateKey = targetKeys[targetKeys.length-1];
            }

            if( lastTags[key].dateKeys[n-1] ){
                updates.push({
                    name: key,
                    count: lastTags[key].count - targetKeys.length - 1,
                    dateKey: lastTags[key].dateKeys[n-1],
                    dateKeys: targetKeys,
                });
            } else {
                updates.push({
                    name: key,
                    count: 0,
                    dateKey: "",
                    dateKeys: targetKeys,
                });
            }

            lastTags[key].count--;
            if( n===0 ){
                if(lastTags[key].dateKeys.length>1){
                    lastTags[key].dateKeys = lastTags[key].dateKeys.slice(1);
                } else {
                    lastTags[key].dateKeys = [];
                }
            } else {
                lastTags[key].dateKeys = lastTags[key].dateKeys.slice(0,n).concat( lastTags[key].dateKeys.slice(n+1) );
            }
            if( lastTags[key].count>0 ){
                lastTags[key].date = lastTags[key].dateKeys[lastTags[key].count-1];
            } else {
                lastTags[key].date="";
            }
        }

        //profile
        let firstDateKey = profile.firstDateKey;
        let lastDateKey = "";
        let dateKeys = profile.dateKeys;

        if( dateKeys.includes(dateKey) ){
            const n = dateKeys.indexOf(dateKey);
            dateKeys = dateKeys.slice(0,n).concat(dateKeys.slice(n+1));
            if( dateKeys.length>1 ){
                firstDateKey = dateKeys[0];
            } else {
                firstDateKey = "";
            }
        } else if( firstDateKey===dateKey ){
            //最初のレポートを削除し、それが1年以上古い（profile.dateKeysに含まれていない）場合、
            //2番目のレポート（新たに最初になるレポート）を取得しなくてはならない
            firstDateKey = "search";
        }
        if( dateKeys.length>1 ){
            lastDateKey = dateKeys[dateKeys.length-1];
        }       

        const newProfile = {...profile,dateKeys:dateKeys,firstDateKey:firstDateKey,lastDateKey:lastDateKey,tags:lastTags,version:profile.version+1};

        store.updateReportAndProfile(report,states.id,newProfile,updates,fromDateKey,toDateKey,true,()=>{
            window.scrollTo(0,0);
            if( profile.firstDateKey!==newProfile.firstDateKey ){
                userState.setProfile(newProfile);
            }
            setStates({...states,yesnoId:"",redirect:true});
        });

    }

    const loadProfile = (func:any)=>{
        if(!userState.user){
            return;
        }
        store.getProfile(userState.user.uid,(profile:Profile)=>{
            userState.setProfile(profile);
            func(profile);
        },()=>{
            setStates({...states,profile:Inc.defaultProfile(),isLoading:false});
        });
    }

    const changeTag = (name:"tag",value:string)=>{
        value = value.replace(".","").replace("/","");
        setStates({...states,[name]:value})
    }

    const newTag = ()=>{
        const name = states.tag.trim();
        if( name==="" ){
            return;
        }
        addTag(name)
    }

    const addTag = (name:string)=>{
        //選択済みのタグか？
        if( states.report.tags[name] ){
            if( states.isTagPick ){
                closeTagPick();
            }
            return;
        }
        const tag:ReportTag = {
            days: 0,
            count: 0,
        }
        setStates({...states,report:{...states.report,tags:{...states.report.tags,[name]:tag}},tag:"",isTagPick:false});
    }

    const removeTag = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.name ){
			return;
        }
        let tags = {...states.report.tags};
        delete tags[e.currentTarget.dataset.name];
        setStates({...states,report:{...states.report,tags:tags},removeTags:states.removeTags.concat(e.currentTarget.dataset.name)});
    }

    const openTagPick = ()=>{
        setStates({...states,isTagPick:true});
    }

    const closeTagPick = ()=>{
        setStates({...states,isTagPick:false});
    }

    const closeToast = ()=>{
        setVali({...vali,message:""});
    }

    const getBaseDateKey = ()=>{
        var dt = new Date();
        dt.setFullYear(dt.getFullYear()-1);
        return dt.getFullYear() + ('0'+(dt.getMonth()+1)).slice(-2) + ('0'+dt.getDate()).slice(-2);
    }

    const isUsedDate = (dateKey:string,funcFound:any,funcNot:any)=>{
        const baseKey = getBaseDateKey();
        //console.log(baseKey,dateKey);
        if( baseKey<=dateKey ){
            if( states.profile.dateKeys.includes(dateKey) ){
                funcFound();
            } else {
                funcNot();
            }
        } else {
            //storeを検索して確認
            if( userState.user ){
                store.findReport(userState.user.uid,dateKey,(id:string)=>{
                    funcFound();
                },()=>{
                    funcNot();
                })
            }
        }
    }

    const confirm = ()=>{
        setStates({...states,yesnoId:"remove"});
    }

    const safeBack = ()=>{
        if( states.edited ){
            setStates({...states,yesnoId:"back"});
        } else {
            setStates({...states,yesnoId:"",redirect:true});
        }
    }

    const answerYes = ()=>{
        if( states.yesnoId==="back" ){
            setStates({...states,yesnoId:"",redirect:true});
        }
        if( states.yesnoId==="remove" ){
            remove();
        }
    }

    const answerNo = ()=>{
        setStates({...states,yesnoId:""});
    }


    useEffect( ()=>{
        if( !userState.user ){
            return;
        }
        if( !props.new && rcp.match.params.id ){
            loadReport(userState.user.uid,rcp.match.params.id);
        } else {
            loadProfile( (profile:Profile)=>{
                setStates({...states,profile:profile,isLoading:false});
            })
        }
    },[userState.user]);

    if( !userState.ready ){
        return (null);
    }

    if( states.redirect ){
        return (
            <Redirect to="/"/>
        )
    }

    let mesYesno = "";
    if( states.yesnoId==="back" ){
        mesYesno = "編集内容は失われますがよろしいですか？";
    }
    if( states.yesnoId==="remove" ){
        mesYesno = "削除してよろしいですか？";
    }
   
    return (
        <div id="event_input" className="miniRoot">
            <div className="paper">
                {states.id==="" ? (
                <ColInput name="日付" id="date" type="date" error={vali.errors.date} value={states.report.date} change={changeReport}/>
                ):(
                <FormControl fullWidth={true} style={{marginBottom:"1.5em"}}>
                    <FormLabel component="label" className="formLabel">日付</FormLabel>
                    <span>{states.report.date}</span>
                </FormControl>
                )}
                <Grid container spacing={1}>
                    <Grid item xs={3} sm={2}>
                        <FormControl fullWidth={true}>
                            <FormLabel component="label" className="formLabel" style={{marginBottom:"0.4em"}}>タグ</FormLabel>
                            <Button size="small" variant="contained" color="default" onClick={openTagPick}>選択</Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                        <ColInput name="" id="tag" type="text" icon="add" error={vali.errors.tag} maxLength={15} onIconClick={newTag} placeholder="タグを追加（15文字以内）" value={states.tag} change={changeTag}/>
                    </Grid>
                </Grid>
                <div className="reportTags">
                    {Object.keys(states.report.tags).map( (name:string,index:number)=>{
                        const key = "tag"+index;
                        return (
                            <div className="tag" key={key}>
                                <FontAwesomeIcon icon="tag"/> <span>{name}</span>
                                <span className="btn" onClick={removeTag} data-name={name}><FontAwesomeIcon icon="times-circle"/></span>
                            </div>
                        )
                    })}
                </div>
                <div className="reportTagsAfter"></div>
                <ColInput name="特記事項（100文字以内・省略可）" id="memo" maxLength={100} type="textarea" error={vali.errors.memo} value={states.report.memo} change={changeReport}/>
                {states.id!=="" ? (
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <FormControl fullWidth={true}>
                                <Button variant="outlined" color="secondary" onClick={confirm}>削除</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth={true}>
                                <Button variant="contained" color="primary" onClick={validate}>決定</Button>
                            </FormControl>
                        </Grid>
                    </Grid>

                ):(
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" onClick={validate}>決定</Button>
                    </FormControl>
                )}
                <div style={{marginTop:"1em",textAlign:"center"}}>
                    <Button variant="contained" color="default" onClick={safeBack}>やめる</Button>
                </div>
            </div>
            <TagPick close={closeTagPick} add={addTag} tags={states.profile.tags} isOpen={states.isTagPick}/>
            <Toast mes={vali.message} close={closeToast}/>
            <Yesno mes={mesYesno} yes={answerYes} no={answerNo} isOpen={states.yesnoId!==""}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default ReportInput;
