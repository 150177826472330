import React,{useEffect,useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SearchIcon from '@material-ui/icons/Search';
import TagInput from './TagInput';
import Loading from './Loading';
import ColInput from './ColInput';
import store from "./store";
import {Tools} from "./Tools";
import {UserContainer} from './UserContainer';
import ClimbUp from './ClimbUp';
import {ReportLastTag,Profile, Inc} from './Inc';
//import "./Main.scss";

interface Props {
    
}

const TagList = (props:Props)=> {
    const [states,setStates] = useState({
        profile: Inc.defaultProfile(),
        removeName: "",
        selected: "",
        tag: "",
        searchTag: "",
        isLoading:true,
    })
    const userState = UserContainer.useContainer();

    const editTag = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.name ){
			return;
        }
        const name = e.currentTarget.dataset.name;
        if(!states.profile.tags[name]){
            return;
        }
        setStates({...states,selected:name});
    }

    const closeTagInput = ()=>{
        setStates({...states,selected:""});
    }

    const updateTag = (name:string,tag:ReportLastTag)=>{
        if(!userState.user){
            return;
        }
        setStates({...states,isLoading:true});
        const uid = userState.user.uid;
        store.getProfile(uid,(profile:Profile)=>{
            saveProfile(uid,{...profile,tags:{...profile.tags,[name]:tag}});
        },()=>{
            
        });
    }

    const removeTag = (name:string)=>{
        if(!userState.user){
            return;
        }
        setStates({...states,isLoading:true});
        const uid = userState.user.uid;
        store.getProfile(uid,(profile:Profile)=>{
            let tags = profile.tags;
            if(tags[name]){
                delete(tags[name]);
                saveProfile(uid,{...profile,tags:tags});
            }
        },()=>{
            
        });
    }

    const saveProfile = (uid:string,profile:Profile)=>{
        store.setProfile(uid,profile,()=>{
            userState.setProfile(profile);
            setStates({...states,profile:profile,selected:"",removeName:"",isLoading:false});
        })    
    }

    const changeTag = (name:string,value:string)=>{
        setStates({...states,tag:value});
    }
    const search = ()=>{
        window.scrollTo(0,0);
        setStates({...states,searchTag:states.tag});
    }

    useEffect( ()=>{
        if( userState.user ){
            setStates({...states,profile:userState.getProfile(),isLoading:false})        
        }
    },[userState.user]);

    if( !userState.hasProfile() ){
        return (
            <Redirect to="/"/>
        )
    }

    const todayKey = Tools.getDateKey();

    return (
        <div id="tag_list" className="smallRoot">
            <div className="paper">
                <div className="reports">
                    <h2 className="withSearch">
                        {states.profile.name}さんのタグ
                        <div className="goSearch"><a href="#search"><SearchIcon/></a></div>
                    </h2>

            {Object.keys(states.profile.tags).map((name:string,index:number)=>{
                const key = "tag"+index;
                const days = Tools.calcDays(states.profile.tags[name].date,todayKey);
                const date = states.profile.tags[name].date.substr(0,4)+"/"+states.profile.tags[name].date.substr(4,2)+"/"+states.profile.tags[name].date.substr(6,2);
                if( name.indexOf(states.searchTag)>=0 || states.searchTag==="" ){
                    return (
                        <div className="tagBox" key={key}>
                            <div className="name">
                                <FontAwesomeIcon icon="tag"/> {name}
                            </div>
                            {states.profile.tags[name].count>0 ? (
                            <div className="days">
                                <span className="count">{states.profile.tags[name].count+states.profile.tags[name].start-1} 回</span>最終回　{date}<span>（{days}日前）</span>
                            </div>
                            ):(
                            <div className="days">
                                最終回　なし
                            </div>
                            )}
                            <div className="btns">
                                <IconButton edge="end" size="small" color="primary" aria-label="delete" onClick={editTag} data-name={name}>
                                    <FontAwesomeIcon icon="edit"/>
                                </IconButton>
                            </div>
                        </div>
                    )
                } else {
                    return (null);
                }
            })}

                </div>
                <div className="search" id="search">


                    <ColInput id="searchTag" name="タグを探す" placeholder="タグ名の一部を入力" value={states.tag} type="text" change={changeTag}/>
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" style={{width:"100%"}} onClick={search}>絞り込む</Button>
                    </FormControl>
                    <div className="goMain">
                        <Link to="/" className="link"><Button variant="outlined" color="default">もどる</Button></Link>
                    </div>
                </div>
            </div>
            <ClimbUp/>
            <TagInput close={closeTagInput} name={states.selected} tag={states.profile.tags[states.selected]} update={updateTag} remove={removeTag}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default TagList;
