import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {Tools} from './Tools';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
import Toast from './Toast';


interface Props {
    close():void,
    name:string,
    update(name:string):void,
    isOpen:boolean,
}

const ProfileName = (props:Props)=> {
    const [states,setStates] = useState({
        name: "",
    })
    const [toast,setToast] = useState("");
    
    const change = (name:"name",value:string)=>{
        setStates({...states,name:value});
    }

    const update = ()=>{
        const value = states.name.trim();
        if(value===""){
            setToast("新しい名前を教えてください");
            return;
        }
        if(value.length>10){
            setToast("名前は10文字以内にしてください");
            return;
        }
        props.update(value);
    }

    const closeToast = ()=>{
        setToast("");
    }

    useEffect(()=>{
        setStates({...states,name:""});
    },[]);

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="tagPicker">
                <ModalHeader close={props.close}/>
                <div className="dialogRoot">
                    <h2>{props.name}</h2>
                    <ColInput id="name" name="新しい名前" placeholder={props.name} type="text" value={states.name} change={change}/>
                    <Button variant="contained" color="primary" onClick={update} style={{width:"100%"}}>変更する</Button>
                </div>
                <Toast close={closeToast} mes={toast}/>
            </div>
        </Modal>
    );
}

export default ProfileName;
