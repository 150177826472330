import React,{useEffect,useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColInput from './ColInput';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MultiLines from './MultiLines';
import TagPick from './TagPick';
import Loading from './Loading';
import store from "./store";
import First from "./First";
import About from './About';
import ClimbUp from './ClimbUp';
import {UserContainer} from './UserContainer';
import {Report,ReportLastTags,Profile,ReportSearch,Inc} from './Inc';
//import "./Main.scss";

interface Props {
    userId?:string,
    profile?:Profile,
}

const Main = (props:Props)=> {
    const defReports:Report[] = [];
    const defTags:ReportLastTags = {};
    const defSearch:ReportSearch = {
        tag: "",
        date: "",
    };
    const [states,setStates] = useState({
        userId: "",
        profile:Inc.defaultProfile(),
        reports:defReports,
        endDateKey: "",
        isEnd:false,
        isLoading:true,
        isTagPick:false,
        search:defSearch,
        tags:defTags,
        redirectTo: "",
    })
    const [about,setAbout] = useState<"company"|"warning"|"copyright"|"">("");

    const userState = UserContainer.useContainer();

    const updateList = (search?:ReportSearch)=>{
        if(!search){
            search = defSearch;
        }
        if( states.userId!=="" ){
            store.getReports( states.userId, "" , search , (reports:Report[])=>{
                let endDateKey = states.endDateKey;
                let isEnd = false;
                if( reports.length>0 ){
                    endDateKey = reports[reports.length-1].doc.dateKey;
                } else {
                    isEnd = true;
                }
                setStates({...states,reports:reports,endDateKey:endDateKey,isEnd:isEnd,isLoading:false});
            });
        }
    }

    const loadMore = ()=>{
        setStates({...states,isLoading:true});
        store.getReports( states.userId, states.endDateKey , states.search , (reports:Report[])=>{
            let endDateKey = states.endDateKey;
            let isEnd = false;
            if( reports.length>0 ){
                endDateKey = reports[reports.length-1].doc.dateKey;
            } else {
                isEnd = true;
            }
            setStates({...states,reports:states.reports.concat(reports),endDateKey:endDateKey,isEnd:isEnd,isLoading:false});
        });
    }

    const changeSearch = (name:string,value:string)=>{
        setStates({...states,search:{...states.search,[name]:value}});
    }
    const search = ()=>{
        setStates({...states,isLoading:true});
        window.scrollTo(0,0);
        updateList(states.search);
    }
    const openTagPick = ()=>{
        setStates({...states,isTagPick:true});
    }
    const closeTagPick = ()=>{
        setStates({...states,isTagPick:false});
    }
    const setSearchTag = (name:string)=>{
        setStates({...states,search:{...states.search,tag:name},isTagPick:false});
    }
    const clearSearchTag = ()=>{
        setStates({...states,search:{...states.search,tag:""}});
    }
    const clearSearchDate = ()=>{
        setStates({...states,search:{...states.search,date:""}});
    }
    const openAbout = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.type ){
			return;
        }
        if( e.currentTarget.dataset.type==="company"){
            setAbout("company");
        }
        if( e.currentTarget.dataset.type==="copyright"){
            setAbout("copyright");
        }
        if( e.currentTarget.dataset.type==="warning"){
            setAbout("warning");
        }
    }
    const closeAbout = ()=>{
        setAbout("");
    }
    const clickTag = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.name ){
			return;
        }
        changeSearch("tag",e.currentTarget.dataset.name as string);
    }

    useEffect( ()=>{
        if( !userState.ready ){
            return;
        }
        if(userState.user){
            if( !userState.hasProfile() ){
                const userId = userState.user.uid;
                store.getProfile(userId,(profile:Profile)=>{
                    userState.setProfile(profile);
                },(error:any)=>{
                    userState.setProfile(Inc.defaultProfile());
                    //console.log("here?");
                    //checkProfile();
                });
                return;
            } else {
                const profile = userState.getProfile();
                if(!profile || profile.name==="" ){
                    setStates({...states,redirectTo:"/profile/new"})
                } else if( props.userId && props.profile ){
                    setStates({...states,userId:props.userId,profile:props.profile});
                } else {
                    setStates({...states,userId:userId,profile:profile});
                }
            }
        } else {
            if( !states.userId || !states.profile ){
                if( props.userId && props.profile ){
                    setStates({...states,userId:props.userId,profile:props.profile});
                    return;
                }
            }
        }
        if( states.userId && states.profile ){
            updateList();
        }
    },[userState.user,states.userId,userState.hasProfile(),props.userId]);

    if( !userState.ready ){
        return <Loading isLoading={true}/>;
    }

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    let userId = "";
    if( userState.user && userState.user.uid ){
        userId = userState.user.uid;
    }

    if( !userState.user && states.userId==="" ){
        return (
            <First/>
        );
    }

    return (
        <div id="report_list" className="smallRoot">
            <div className="paper">
                <div className="reports">
                <h2 className="withSearch">
                    {states.profile.name}さんのピータ
                    <div className="goSearch"><a href="#search"><SearchIcon/></a></div>
                </h2>

                {states.reports.map((report:Report,index:number)=>{
                    const key = "report"+index;
                    const to = "/report/input/"+report.id;
                    const y = report.doc.dateKey.substr(0,4);
                    const m = report.doc.dateKey.substr(4,2);
                    const d = report.doc.dateKey.substr(6,2);
                    const lis = (
                        <ul className="tags">
                        {Object.keys(report.doc.tags).map( (name:string,index:number)=>{
                            const key = "tag"+index;
                            const tag = report.doc.tags[name];
                            let buri = "";
                            if( tag.count>1 ){
                                buri = String(tag.days) + "日ぶり";
                            }
                            return (
                                <li key={key} onClick={clickTag} data-name={name}><a href="#search">
                                    <FontAwesomeIcon icon="tag"/> {name}（{buri}{tag.count+states.profile.tags[name].start-1}回目）
                                </a></li>
                            )
                        })}
                        </ul>
                    );
                    const editBtn = (
                        <Link to={to} className="link"><IconButton size="small" color="primary" aria-label="edit"><FontAwesomeIcon icon="edit"/></IconButton></Link>
                    )
                    const lines = <MultiLines text={report.doc.memo}/>;

                    return (
                        <React.Fragment key={key}>
                            <Hidden implementation="js" xsDown>
                                <div key={key} className="box">
                                    <div className="date">
                                        {m}月{d}日<br/>
                                        <div className="year">{y}年</div>
                                        {states.userId===userId && <div className="menu">{editBtn}</div>}
                                    </div>
                                    <div className="text">
                                        <div>{lines}</div>
                                        {lis}
                                    </div>
                                </div>
                            </Hidden>
                            <Hidden implementation="js" smUp>
                                <div key={key} className="smallBox">
                                    <div className="date">
                                        {m}月{d}日
                                        <div className="year">{y}年</div>
                                        {states.userId===userId && <div className="menu">{editBtn}</div>}
										{states.userId!==userId && <div className="menu"><IconButton size="small" color="primary" aria-label="edit" disabled={true}><FontAwesomeIcon icon="edit"/></IconButton></div>}
                                    </div>
                                    <div className="text">
                                        <div>{lines}</div>
                                        {lis}
                                    </div>
                                </div>
                            </Hidden>
                        </React.Fragment>
                    )
                })}
                    {!states.isEnd && states.profile.firstDateKey<states.endDateKey &&
                    <div className="more">
                        <Button variant="outlined" color="primary" onClick={loadMore} style={{width:"100%"}}>もっと表示</Button>
                    </div>
                    }
                </div>
                <div className="search" id="search">
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <FormControl fullWidth={true}>
                                <FormLabel component="label" className="formLabel" style={{marginBottom:"0.4em"}}>検索タグ</FormLabel>
                                <Button size="small" variant="contained" color="default" onClick={openTagPick}>選択</Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <ColInput name="" id="tag" type="text" icon="cancel" onIconClick={clearSearchTag} maxLength={20} placeholder="" value={states.search.tag} change={changeSearch}/>
                        </Grid>
                    </Grid>
                    <ColInput name="日付" id="date" type="date" icon="cancel" onIconClick={clearSearchDate} value={states.search.date} change={changeSearch}/>

                    <div className="btns">
                        <Button variant="outlined" color="primary" onClick={search}>検索する</Button>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <Button variant="text" onClick={openAbout} data-type="company">運営会社</Button>
                <Button variant="text" onClick={openAbout} data-type="copyright">著作権等</Button>
                <Button variant="text" onClick={openAbout} data-type="warning">注意事項</Button>
            </div>
            <ClimbUp/>
            <TagPick close={closeTagPick} add={setSearchTag} tags={states.profile.tags} isOpen={states.isTagPick}/>
            <About type={about} isOpen={about!==""} close={closeAbout}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default Main;
