import React,{useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import ColInput from './ColInput';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {UserContainer} from './UserContainer';
import store from './store';
import Loading from './Loading';
import Toast from './Toast';
import {Profile} from './Inc';
//import useStyles from './Styles';


interface Props {
}

const ProfileNew = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const [states,setStates] = useState({
        //profile: Inc.defaultProfile(),
        name: "",
        redirectTo: "",
        isLoading: false,
    });
    const [toast,setToast] = useState("");
    const changeProfile = (name:keyof Profile,value:string)=>{
        //setStates({...states,profile:{...states.profile,[name]:value}})
        setStates({...states,[name]:value})
    }
    const change = ()=>{
        if(!userState.user){
            return;
        }
        const value = states.name.trim();
        if(value===""){
            setToast("名前を教えてください");
            return;
        }
        if(value.length>10){
            setToast("名前は10文字以内にしてください");
            return;
        }
        const profile = userState.getProfile();
        let newProfile = {...profile,name:states.name,publicKey:store.getUniqueId()};

        setStates({...states,isLoading:true});
        store.setProfile(userState.user.uid,newProfile,()=>{
            userState.setProfile(newProfile);
            setStates({...states,redirectTo:"/",isLoading:false});
        })
    }
    const closeToast = ()=>{
        setToast("");
    }

    useEffect(()=>{
        const profile = userState.getProfile();
        setStates({...states,redirectTo:"",name:profile.name});
    },[]);

    if( states.redirectTo!=="" ){
        return (
            <Redirect to={states.redirectTo}/>
        )
    }

    return (
        <div id="profile_new" className="smallRoot">
            <div className="paper">
                <div className="profile">
                    <h2>peterを始める</h2>
                    <ColInput name="きみの名は？" id="name" type="text" placeholder="10文字以内" value={states.name} change={changeProfile}/>
                    <FormControl fullWidth={true}>
                        <Button variant="contained" color="primary" onClick={change}>決定</Button>
                    </FormControl>
                </div>
            </div>
            <Toast close={closeToast} mes={toast}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default ProfileNew;
